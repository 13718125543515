import React from 'react';

const Logo = (props) => {
  return (
    <svg version='1.1' x='0px' y='0px' viewBox='0 0 595.3 91' id='logo'>
      <path
        className='st0'
        d='M51.3,31.3h-3.8V47c0,6-3.4,7.9-7.7,7.9c-5.2,0-7.7-2.7-7.7-7.9V31.3h-3.8v16.4c0,7.3,5,10.7,11.5,10.7  c7.3,0,11.5-4.1,11.5-10.7V31.3z'
      />
      <polygon
        className='st0'
        points='79.4,31.3 75.7,31.3 75.7,52 59.7,31.3 56.4,31.3 56.4,58 60,58 60,37.4 76,58 79.4,58 '
      />
      <rect x='85.5' y='31.3' className='st0' width='3.8' height='26.7' />
      <polygon
        className='st0'
        points='112.2,31.3 93,31.3 93,34.6 100.8,34.6 100.8,58 104.7,58 104.7,34.6 112.2,34.6 '
      />
      <polygon
        className='st0'
        points='131,31.3 115.8,31.3 115.8,58 131.3,58 131.3,54.7 119.7,54.7 119.7,45.9 130.6,45.9 130.6,42.6   119.7,42.6 119.7,34.6 131,34.6 '
      />
      <path
        className='st0'
        d='M145.1,31.3h-8.9V58h8.9c9.4,0,14.2-6.5,14.2-13.4C159.3,38.3,155,31.3,145.1,31.3 M140,54.7V34.6h4.5  c7.3,0,10.8,4.7,10.8,10.1c0,5.6-3.8,10.1-10.8,10.1H140z'
      />
      <polygon
        className='st0'
        points='232.8,31.3 229,31.3 229,58 243.9,58 243.9,54.7 232.8,54.7 '
      />
      <path
        className='st0'
        d='M284.5,31.3h-8.2V58h3.8V46.4h4.8l7.3,11.6h4.6c-1.8-2.5-8-12.6-8-12.6c0.6-0.3,4.5-1.6,4.5-6.7  C293.3,34.1,289.9,31.3,284.5,31.3 M280.2,43.2v-8.6h3.9c3.7,0,5.3,1.8,5.3,4.3c0,2.2-1.3,4.3-5.3,4.3H280.2z'
      />
      <path
        className='st0'
        d='M186,30.9c-8.5,0-14.7,6-14.7,14.1c0,5.6,4.4,13.3,14.4,13.3c4.6,0,7.4-1,7.7-1v-3.8c-0.2,0.1-3.1,1.4-7.3,1.4  c-6.4,0-10.8-4.2-10.8-10.2c0-5.9,4.5-10.2,10.7-10.2c3.7,0,7.2,1.5,7.5,1.6v-3.8C193.1,32.1,189.9,30.9,186,30.9'
      />
      <path
        className='st0'
        d='M209.9,30.9c-7.9,0-14.1,6.1-14.1,13.7c0,7.6,6.3,13.7,14.1,13.7c7.9,0,14.1-6.1,14.1-13.7  C224,37.1,217.7,30.9,209.9,30.9 M209.9,54.9c-5.7,0-10.2-4.6-10.2-10.2c0-5.6,4.5-10.2,10.2-10.2c5.7,0,10.2,4.6,10.2,10.2  C220.1,50.3,215.6,54.9,209.9,54.9'
      />
      <path
        className='st0'
        d='M257.9,30.9c-7.9,0-14.1,6.1-14.1,13.7c0,7.6,6.3,13.7,14.1,13.7c7.9,0,14.1-6.1,14.1-13.7  C272,37.1,265.7,30.9,257.9,30.9 M257.9,54.9c-5.7,0-10.2-4.6-10.2-10.2c0-5.6,4.5-10.2,10.2-10.2c5.7,0,10.2,4.6,10.2,10.2  C268.1,50.3,263.6,54.9,257.9,54.9'
      />
      <path
        className='st0'
        d='M307.6,30.9c-5.4,0-8.6,3.2-8.6,7.1c0,3.6,2,5.6,4.9,7c0.7,0.3,2.8,1.3,3.8,1.8c1.5,0.7,4.2,2,4.2,4.4  c0,1.8-1.8,3.7-5.5,3.7c-3.7,0-5.4-1.5-5.9-1.9l-1.8,3.3c0.3,0.2,2.5,2,7.8,2c4.8,0,9.2-2.2,9.2-7.4c0-4.5-4.1-6.5-6.6-7.6  c-0.9-0.4-2.1-1-2.9-1.4c-1.8-0.9-3.3-2-3.3-4c0-1.8,1.6-3.5,4.8-3.5c3.1,0,4.9,1.2,5.3,1.4l1.2-3.3  C313.9,32.4,311.6,30.9,307.6,30.9'
      />
      <g>
        <polygon
          className='st0'
          points='372.3,31.4 358,31.4 358,58.2 361.9,58.2 361.9,45.8 372.3,45.8 372.3,42.5 361.9,42.5 361.9,34.8    372.3,34.8  '
        />
        <path
          className='st0'
          d='M339.6,31.1c-7.9,0-14.1,6.1-14.1,13.7c0,7.6,6.3,13.7,14.1,13.7c7.9,0,14.1-6.1,14.1-13.7   C353.7,37.2,347.4,31.1,339.6,31.1 M339.6,55c-5.7,0-10.2-4.6-10.2-10.2c0-5.6,4.5-10.2,10.2-10.2c5.7,0,10.2,4.6,10.2,10.2   C349.8,50.5,345.3,55,339.6,55'
        />
        <polygon
          className='st0'
          points='450,31.4 446.4,31.4 446.4,52.2 430.3,31.4 427,31.4 427,58.2 430.6,58.2 430.6,37.6 446.6,58.2    450,58.2  '
        />
        <polygon
          className='st0'
          points='567.2,31.4 563.6,31.4 563.6,52.2 547.5,31.4 544.3,31.4 544.3,58.2 547.9,58.2 547.9,37.6    563.9,58.2 567.2,58.2  '
        />
        <polygon
          className='st0'
          points='491.6,31.4 472.4,31.4 472.4,34.8 480.2,34.8 480.2,58.2 484.1,58.2 484.1,34.8 491.6,34.8  '
        />
        <polygon
          className='st0'
          points='513.1,31.4 493.9,31.4 493.9,34.8 501.8,34.8 501.8,58.2 505.6,58.2 505.6,34.8 513.1,34.8  '
        />
        <polygon
          className='st0'
          points='422.2,31.4 407,31.4 407,58.2 422.6,58.2 422.6,54.9 410.9,54.9 410.9,46.1 421.8,46.1 421.8,42.8    410.9,42.8 410.9,34.8 422.2,34.8  '
        />
        <polygon
          className='st0'
          points='470,31.4 454.8,31.4 454.8,58.2 470.4,58.2 470.4,54.9 458.7,54.9 458.7,46.1 469.6,46.1 469.6,42.8    458.7,42.8 458.7,34.8 470,34.8  '
        />
        <path
          className='st0'
          d='M526.8,31.1c-7.9,0-14.1,6.1-14.1,13.7c0,7.6,6.3,13.7,14.1,13.7c7.9,0,14.1-6.1,14.1-13.7   C540.9,37.2,534.7,31.1,526.8,31.1 M526.8,55c-5.7,0-10.2-4.6-10.2-10.2c0-5.6,4.5-10.2,10.2-10.2c5.7,0,10.2,4.6,10.2,10.2   C537,50.5,532.5,55,526.8,55'
        />
        <path
          className='st0'
          d='M393.3,31.4h-8.7v26.8h9.2c6.8,0,9.2-3.8,9.2-7.1c0-5.6-4.5-6.7-5-6.8v-0.1c0.3-0.1,3.6-1.3,3.6-5.9   C401.5,34.7,398.9,31.4,393.3,31.4 M388.4,43v-8.2h4.3c3,0,4.9,1.5,4.9,4.1c0,2.7-2,4.1-4.9,4.1H388.4z M388.4,54.9v-8.6h5.1   c3.9,0,5.5,1.9,5.5,4.3c0,2-1.1,4.3-5.5,4.3H388.4z'
        />
        <path
          className='st0'
          d='M573.2,52.9c-1.5,0-2.7,1.2-2.7,2.6c0,1.5,1.2,2.6,2.7,2.6c1.5,0,2.6-1.2,2.6-2.6   C575.9,54.1,574.7,52.9,573.2,52.9'
        />
      </g>
    </svg>
  );
};

export default Logo;
